.bg__menu{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    animation: fadeIn 0.5s linear;
    z-index: 1050;
    background-color: rgba(#000, 0.6);

    &.hide{
        animation: fadeOut 0.5s linear;
    }
}
.main__logo{
	padding: 20px 0px;
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	font-family: 'franklinextrabold';
	font-size: 14px;
	line-height: 14px;
	font-style: italic;
}
.menu__link{
	&:hover{
		text-decoration: underline;
	}
}
.menu__item{
	&.menu__item--active{
		.menu__link{
			color: #a43133;
		}
	}
}
.btn__topo{
	display:flex;
	align-items: center;
	justify-content: center;
	min-height: 50px;
	padding: 5px;
	width: auto;
	min-width: 150px;
	max-width: 100%;
	color: #a43133;
	border: 2px solid #a43133;
	border-radius: 5px;
	font-family: "franklinextrabold";
	transition: all 0.3s linear;

	&:hover{
		background-color: #a43133;
		color: #fff;
		text-decoration: none;
	}

	.icon__mask{
		margin-right: 5px;
	}
}
.segura__telefone__topo{
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-down (md) {
		margin-top: 10px;
	}

	.telefone__topo{
		width: 50px;
		height: 50px;
		background-color: #a43133;
		color: #fff;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		line-height: 18px;

		&:hover{
			text-decoration: none;
			background-color: darken(#a43133, 10%);
		}
	}
}
.dropdown__controls{
	position: relative;

	button{
		width: 20px;
		height: 20px;
		position: absolute;
		top: calc(50% - 10px);
		right: 10px;
		background-color: #fff;
		color: #a43133;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		line-height: 12px;
		border-radius: 50%;
		border: 1px solid #a43133;
		transition: all 0.3s linear;
		padding: 0px;
		padding-top: 2px;

		&[aria-expanded="true"] {
			transform: rotate(90deg);
		}
	}
}
.dropmenu{

	ul{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;

		@include media-breakpoint-up (lg) {
			overflow-y: auto;
			max-height: calc(37px * 5);

			&::-webkit-scrollbar{
				width: 8px;
			}
			&::-webkit-scrollbar-thumb{
				background-color: #fff;
				border-radius: 4px;
			}
			&::-webkit-scrollbar-track{
				background-color: darken(#a43133, 20%);
			}
		}

	}

	// li{
	// 	border-bottom: 1px solid #fff;
	// }

	a{
		display: block;
		width: 100%;
		padding: 10px;
		background-color: #a43133;
		color: #fff;
		border-bottom: 1px solid #fff;
		font-size: 14px;
		line-height: 16px;

		&:hover{
			color: #fff;
			text-decoration: none;
			background-color: darken(#a43133, 10%);
		}
	}
}
@include media-breakpoint-up (lg) {
	.topo__main{
		position: relative;
		background-color: #fff;
		border-bottom: 1px solid #dbdbdb;

		.container{
			display: flex;
			justify-content: space-between;
		}
	}
	.topo__mobile{
		display: none;
	}
	.main__menu{
		display: flex;
		justify-content: flex-end;
		border-left: 1px solid #dbdbdb;
		padding-left: 20px;

		.dropdown__controls{
			display: flex;

			button{
				display: none;
			}
		}
	}
	.main__menu{
		.menu__item{
			position: relative;
			&:hover{
				.dropmenu{
					display: block;
				}
			}
		}
	}
	.dropmenu{
		position: absolute;
		top: 100% !important;
		left: calc(50% - 100px);
		width: 200px;
		max-height: 300px;
		overflow-y: auto;
		background-color: #fff;
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;
		box-shadow: 0px 0px 8px rgba(#000, 0.4);
		margin: 0px !important;
		z-index: 1000;

		li{
			display: flex;

			&:last-child{
				a{
					border-bottom: none;
				}
			}

			a{
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				width: 100%;
				text-align: center;
				color: #fff;
				transition: all 0.3s linear;
				font-family: 'poppinsregular';
				border-bottom: 1px solid rgba(#fff, 40%);

				&:hover{
					color: #fff;
					text-decoration: none;
					background-color: darken(#a43133, 10%);
				}
			}
		}
	}
	.segura__btn__topo{
		display: flex;
		align-items: center;
		padding-right: 20px;
		border-right: 1px solid #dbdbdb;

		.btn__topo{
			margin-right: 20px;
		}
	}
	.menu__link{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		color: #888888;
		z-index: 50;
		padding: 0px 5px;
		margin: 0px 5px;
		&:hover{
			text-decoration: none;
			color: #a43133;
		}
	}
	.menu__item{
		display: flex;

		&:hover{
			.menu__link{
				&:after{
					width: 100%;
				}
			}
		}
	}

}
@include media-breakpoint-only (lg) {
	.segura__btn__topo{
		display: flex;
		align-items: center;
		padding-right: 20px;
		border-right: 1px solid #dbdbdb;
		flex-direction: column;
		justify-content: center;

		.btn__topo{
			margin-bottom: 10px;

			&:last-child{
				margin-bottom: 0px;
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.segura__btn__topo{
		padding: 10px;
		.btn__topo{
			margin-bottom: 10px;

			&:last-child{
				margin-bottom: 0px;
			}
		}

	}
	.topo__main{
		position: fixed;
		left: -250px;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		transition: all 0.5s linear;
		background-color: #fff;
		z-index: 1080;
		color: #747578;

		.pesquisa__toggler{
			display: none;
		}

		.logo__link{
			display: block;
			text-align: center;
			padding: 5px;
			margin-bottom: 10px;
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
	}
	.topo__mobile{
		background-color: #fff;
		color: #747578;
		padding: 10px 0px;
		border-bottom: 1px solid #dbdbdb;


		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.mbl__logo{
		max-width: 200px;
		width: 100%;
	}
	.main__logo{
		padding: 10px;
		margin-bottom: 10px;
	}
	.mbl__toggler{
		border: none;
		background-color: #fff;
		font-size: 30px;
		line-height: 30px;
		color: #a43133;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.menu__item{
		&.menu__item--active{
			.menu__link{
				background-color: #a43133;
			color: #fff;
			}
		}
	}

	.main__menu{
		margin-bottom: 20px;

		.dropmenu{
			list-style: none;
			padding: 0px;
			margin: 0px;

			a{
				display: block;
				padding: 10px;
				background-color: darken(#a43133, 20%);
				color: #fff;
				font-family: 'franklinextrabold';
				border-bottom: 1px solid #fff;
			}
		}
	}
	.menu__link{
		padding: 10px;
		border-bottom: 1px solid #a43133;
		display: block;
		color: #a43133;
		position: relative;

		&:hover{
			background-color: #a43133;
			color: #fff;
			text-decoration: none;
		}
	}
}
