.banner__carousel{
	max-width: 1920px;
	width: 100%;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;

	.carousel-inner{
		max-width: 1920px;
		width: 160%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.carousel-indicators{
		li{
			width: 15px;
			height: 15px;
			opacity: 1;
			border-radius: 5px;
			border: none;
			transition: all 0.3s linear;

			&.active{
				width: 25px;
			}
		}
	}
}
.equipe__cards{
	padding: 72px 0px 52px 0px;

	.col-lg-6{
		display: flex;
	}
}
.equipe__segura__card{
	border: 1px solid #dbdbdb;
	display: flex;
	align-items: flex-start;
	padding: 40px 30px;
	margin-bottom: 20px;

	@include media-breakpoint-down (md) {
		margin-bottom: 20px;
	}

	.equipe__icone{
		margin-right: 10px;
		min-width: 60px;
		text-align: center;
	}
	.equipe__titulo{
		font-family: 'franklinextrabold';
		font-size: 18px;
		line-height: 22px;
		color: #aa2a3e;
		margin-bottom: 12px;
		font-style: italic;
	}
	.equipe__desc{
		font-size: 16px;
		line-height: 20px;
	}
}
.bruno{
	overflow: hidden;
	position: relative;

	@include media-breakpoint-up (lg) {
		.col-lg-6{
			padding: 0px;
		}
		.col-lg-6{
			&:first-child{
				position: relative;
				z-index: 50;
			}
			&:last-child{
				position: relative;
				z-index: 40;
			}
		}
	}
	@include media-breakpoint-down (md) {
		.col-lg-6{
			&:first-child{
				order: 2;
			}
			&:last-child{
				order: 1;
			}
		}
	}

	.bruno__imagem{
		position: relative;
		@include media-breakpoint-up (lg) {
			min-height: 600px;
			img{
				position: absolute;
				top: 0px;
				right: 0px;
				max-width: unset;
				width: 945px;
			}
		}
	}
	.bruno__texto{
		background-color: #aa2a3e;
		color: #fff;
		position: relative;
		padding: 80px 20px;

		@include media-breakpoint-up (lg) {
			padding: 80px 20px;
			margin-top: 120px;

			&:after{
				position: absolute;
				content: '';
				background-color: #aa2a3e;
				top: 0px;
				left: -120px;
				width: 100vw;
				height: 100%;
				z-index: -1;
				border-radius: 10px 0px 0px 10px;
			}
		}
	}
}
.bruno__titulo{
	font-family: 'franklinextrabold';
	font-size: 32px;
	line-height: 34px;
	font-style: italic;
	margin-bottom: 20px;
}
.bruno__desc{
	text-align: justify;
	margin-bottom: 30px;
}
.btn__conteudo{
	min-width: 150px;
	display: inline-flex;
	background-color: #fff;
	color: #aa2a3e;
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	height: 50px;
	padding: 15px 30px;
	font-family: 'franklinextrabold';
	font-style: italic;
	transition: all 0.3s linear;

	&:hover{
		background-color: darken(#a43133, 15%);
		color: #fff;
		text-decoration: none;
	}
}
.atendimento{
	padding: 76px 0px;
}
.titulo__atendimento{
	font-family: 'franklinextrabold';
	font-size: 35px;
	line-height: 37px;
	color: #a43133;
	margin-bottom: 30px;
	text-align: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.cliente__card{
	max-width: 150px;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	border: 2px solid #ededed;
	transition: all 0.3s linear;
	border-radius: 5px;

	&.active{
		pointer-events: none;
		border-color: #a43133;
	}

	&:hover{
		border-color: #a43133;
	}
}
.owl__atendimento.owl-carousel{
	@include media-breakpoint-up (lg) {
		max-width: 930px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			top: calc(50% - 15px);
			width: 30px;
			height: 30px;
			border: 2px solid #9c9c9c;
			color: #9c9c9c;
			transition: all 0.3s linear;
			border-radius: 50%;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover{
				border-color: #a43133;
				color: #a43133;
				text-decoration: none;
			}
		}
		button.owl-prev{
			left: -15px;

			@include media-breakpoint-up (xl) {
				left: -100px;
			}
		}
		button.owl-next{
			right: -15px;
			@include media-breakpoint-up (xl) {
				right: -100px;
			}
		}
	}
}
.interessou{
	padding: 74px 0px;
	background-attachment: fixed;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: #fff;
	text-align: center;
}
.titulo__interesse{
	font-family: 'franklinextrabold';
	font-size: 35px;
	line-height: 39px;
	margin-bottom: 35px;
	font-style: italic;
}
.btns__interesse{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.btn__conteudo{
		margin: 10px;
	}
}
.interna{
	.bruno{
		.bruno__texto{
			background-color: transparent;
			color: #888888;
			margin-top: 0px;
			padding: 25px 15px;

			.bruno__titulo{
				color: #a43133;
			}
			&:after{
				display: none;
			}
		}
	}
}
.quem-somos{
	padding-top: 30px;
	padding-bottom: 70px;
}
.catalogos{
	padding: 90px 0px;

	.owl__atendimento{
		margin-bottom: 40px;
	}

	.row{
		.col-lg-3,
		.col-lg-6{
			margin-bottom: 30px;
		}
	}
}
.segura__marca{
	margin-bottom: 30px;
	text-align: center;

	a{
		display: block;
		max-width: 350px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}
}
.catalogo__segura,
.produto__segura{

	max-width: 258px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	background-color: transparent;
	border: none;
	text-align: left;
}
.produto__segura{
	max-width: 520px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	background-color: transparent;
	border: none;
	text-align: left;
}
.catalogo__card{
	overflow: hidden;
	border-radius: 10px;
	border: 1px solid #dbdbdb;
	padding: 6px;
	background-color: #fff;
	position: relative;
	margin-bottom: 15px;
	text-align: center;

	&:hover{
		&:after{
			opacity: 0.5;
		}
		&:before{
			opacity: 1;
		}
	}

	&:after{
		position: absolute;
		top: 6px;
		left: 6px;
		content: '';
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background-color: #a43133;
		opacity: 0;
		z-index: 50;
		transition: all 0.3s linear;
	}
	&:before{
		width: 50px;
		height: 50px;
		border-radius: 5px;
		background-color: #a43133;
		content: '';
		background-image: url(../images/catalogo__icone.png);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 70;
		opacity: 0;
		transition: all 0.3s linear;
		border-radius: 5px;
	}

	img{
		position: relative;
		z-index: 40;
		border-radius: 10px;
	}
}
.produto__segura{

	&:hover{
		text-decoration: none;
	}
	.catalogo__card{
		&:before{
			background-image: url(../images/produto__icone.png) !important;
		}
	}
}
.catalogo__titulo{
	font-family: 'franklinextrabold';
	color: #a43133;
	font-style: italic;
	font-size: 18px;
	line-height: 22px;
}
.titulo__interna{
	font-family: 'franklinextrabold';
	font-size: 35px;
	line-height: 39px;
	color: #a43133;
	text-align: center;
	font-style: italic;
	margin-bottom: 50px;
}
.btn__center{
	display: flex;
	justify-content: center;
	padding-top: 20px;
}
.btn__interna{
	min-width: 150px;
	display: inline-flex;
	background-color: transparent;
	color: #a43133;
	border-radius: 5px;
	border: 2px solid #a43133;
	align-items: center;
	justify-content: center;
	height: 50px;
	padding: 15px 30px;
	font-family: 'franklinextrabold';
	font-style: italic;
	transition: all 0.3s linear;

	&:hover{
		background-color: #a43133;
		color: #fff;
		text-decoration: none;
	}
}
.contato__form{
	.form-group{
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		label{
			font-size: 16px;
			line-height: 18px;
			color: #888888;
			font-family: 'franklinextrabold';
			font-style: italic;
		}
	}
}
.titulo__contato__interna{
	font-size: 16px;
	line-height: 18px;
	color: #888888;
	font-family: 'franklinextrabold';
	font-style: italic;
}
.segura__titulo__contato{
	margin-bottom: 20px;
}
.contato__box__interna{
	margin-bottom: 10px;
	font-size: 16px;
	line-height: 18px;
	& > * {
		display: inline;
		margin-right: 3px;
	}
}
.orcamento__form{
	max-width: 800px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.btns__orcamento{
	display:flex;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: row !important;

	& > * {
		margin: 5px;
	}

	@include media-breakpoint-down (md) {
		flex-direction: column;

		.btn__interna{
			order: 2;
		}
		.custom__file{
			order: 1;
		}
	}
}
.custom__file{
	max-width: 250px;

	label{
		display: none;
	}
	input{
		height: 45px;
	}
	.v-file-label{
		padding: 5px 30px 5px 30px;
		background-color: transparent;
		color: #a43133;
		border: none;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 5px;
		height: 50px;
		border: 2px solid #a43133;
		font-size: 14px;
		line-height: 16px;
		z-index: 500;
		transition: all 0.3s linear;

		.v-file-output{
			text-align: center;
			color: #a43133;
			font-family: 'franklinextrabold';
			font-size: 14px;
			line-height: 16px;
		}

		&:hover{
			background-color: #a43133;
			text-decoration: none;

			.v-file-button{
				color: #fff;
			}
			.v-file-output{
				color: #fff;
			}
		}

		.v-file-button{
			display: flex;
			align-items: center;
			color: #a43133;
			justify-content: center;
			text-align: center;
			transition: all 0.3s linear;
			background-color: transparent;
			border: none;
			padding: 0px;
			margin-right: 5px;
		}
	}
}
.img__hidden{
	display: none;
}
.download__pdf{
	padding: 10px 15px;
	background-color: transparent;
	border: none;
	font-size: 16px;
	line-height: 16px;
	color: #aa2a3e;
	font-family: franklinbold;
	margin-top: 10px;

	.fas{
		margin-left: 5px;
	}
}
.contato__mapa{
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 20px;
}
.contato__input{
	padding: 22px 18px;
	font-size: 14px;
	line-height: 18px;
	border: 1px solid #888888;
	border-radius: 5px;
	display: block;
	width: 100%;
	resize: none;
}
.tels__input{
	display: grid;
	grid-template-columns: 80px 1fr;
	column-gap: 20px;
	width: 100%;
}
.custom__checkbox{
	padding-top: 0px;

	.custom__check__input{
		position: fixed;
		left: -200vw;

		&:checked{
			~ .custom__check__label{
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.custom__check__label{
		position: relative;
		font-family: 'franklinregular' !important;
		font-size: 14px !important;
		line-height: 16px !important;
		padding-left: 24px;
		cursor: pointer;
		font-style: italic;

		a{
			text-decoration: underline;
		}

		&:after{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: currentColor;
			content: '';
			position: absolute;
			top: calc(50% - 5px);
			left: 4px;
			opacity: 0;
		}
		&:before{
			width: 18px;
			height: 18px;
			border: 2px solid currentColor;
			border-radius: 50%;
			content: '';
			position: absolute;
			left: 0px;
			top: calc(50%  - 9px);
		}
	}
}
