.rodape{
	background-color: #a43133;
	color: #fff;
	padding-top: 76px;
}
.row__custom{
	display: grid;
	grid-template-columns: 21% 32% 24% 23%;
	row-gap: 20px;

	& > * {
		padding-left: 10px;
		padding-right: 10px;
	}

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-down (xs) {
		grid-template-columns: repeat(1, 1fr);
	}
}
.news__form{
	color: #fff;

	.newsletter__form{
		margin-bottom: 5px;
	}
}
.rodape__creditos{
	background-color: #fff;
	padding: 22px 30px;
	border-radius: 10px 10px 0px 0px;
	color: #a43133;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	line-height: 14px;
	margin-top: 70px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
		justify-content: center;
	}

	.creditos__cima{
		span{
			font-family: 'franklinbold';
		}
		margin-bottom: 5px;
	}
}
.sociais{
	display: flex;
	align-items: center;
	justify-content:center;

	@include media-breakpoint-down (md) {
		margin-top: 10px;
	}

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #a43133;
		color: #fff;
		width: 40px;
		height: 40px;
		border-radius: 5px;
		font-size: 22px;
		line-height: 22px;
		margin: 5px;
		transition: all 0.3s linear;

		&:hover{
			background-color: darken(#a43133, 10%);
			color: #fff;
			text-decoration: none;
		}
	}
}
.gv8__box{
	display: flex;
	align-items: center;

	svg{
		margin-left: 5px;
	}
}
.rodape__nav{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 10px;
	row-gap: 10px;
	font-size: 15px;
	line-height: 18px;
}
.titulo__rodape{
	font-family: 'franklinextrabold';
	font-size: 18px;
	line-height: 22px;
	color: #fff;
	font-style: italic;
	margin-bottom: 15px;
}
.newsletter__desc{
	text-align: justify;
	margin-bottom: 20px;
}
.endereco__box{
	font-size: 15px;
	line-height: 18px;
}
.contato__box{
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	font-size: 15px;
	line-height: 18px;

	.contato__box__titulo{
		font-family: 'franklinextrabold';
		font-style: italic;
		margin-bottom: 0px;
	}

	.contato__box__icone{
		width: 34px;
		height: 34px;
		background-color: #fff;
		border-radius: 5px;
		color: #a43133;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 34px;
		margin-right: 10px;
		font-size: 18px;
		line-height: 18px;
	}
}
.rodape__logo{
	margin-bottom: 40px;
}
.rodape__desc{
	font-size: 15px;
	line-height: 18px;

	span{
		font-family: 'franklinextrabold';
	}
}
.newsletter__form{
	display: flex;
	background-color: #fff;
	color: #888888;
	border-radius: 5px;
	overflow: hidden;

	.news__group{
		width: 100%;
		display: flex;
	}

	.news__input{
		width: 100%;
		display: block;
		background-color: #fff;
		color: #888888;
		border: none;
		padding: 16px;

		&::placeholder{
			color: #888888;
		}
	}

	.news__btn{
		color: #a43133;
		background-color: transparent;
		border: none;
		font-size: 20px;
		line-height:20px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 40px;
	}


}
.telegram__fixed{
	position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;;
    background-color: #0088CC;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    line-height: 40px;
    z-index: 800;
	right: 15px;
	bottom: 85px;

	&:hover{
		text-decoration: none;
		color: #fff;
		animation: rubberBand 1s linear;
	}

	@include media-breakpoint-down (md) {
		width: 40px;
    	height: 40px;
		font-size: 25px;
    	line-height: 25px;
		bottom: 55px;
	}

	// &:after{
    //     content: '';
    //     position: absolute;
    //     top: 0px;
    //     left: 0px;
    //     width: 100%;
    //     height: 100%;
    //     border-radius: 50%;
    //     background-color: #0088CC;
    //     animation: pulsar 2s linear infinite;
    //     z-index: -1;
    // }
}
@keyframes pulsar{
    0%{
        opacity: 0.7;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        transform: scale(2);
    }
}
