@font-face {
    font-family: 'franklinblack';
    src: url('../fonts/franklinblack/LibreFranklin-Black.woff2') format('woff2'),
        url('../fonts/franklinblack/LibreFranklin-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'franklinbold';
    src: url('../fonts/franklinbold/LibreFranklin-Bold.woff2') format('woff2'),
        url('../fonts/franklinbold/LibreFranklin-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'franklinextrabold';
    src: url('../fonts/franklinextrabold/LibreFranklin-ExtraBold.woff2') format('woff2'),
        url('../fonts/franklinextrabold/LibreFranklin-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'franklinlight';
    src: url('../fonts/franklinlight/LibreFranklin-Light.woff2') format('woff2'),
        url('../fonts/franklinlight/LibreFranklin-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular/Poppins-Regular.eot');
    src: url('../fonts/poppins-regular/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins-regular/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/poppins-regular/Poppins-Regular.woff') format('woff'),
        url('../fonts/poppins-regular/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/poppins-regular/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'franklinregular';
    src: url('../fonts/franklinregular/LibreFranklin-Regular.woff2') format('woff2'),
        url('../fonts/franklinregular/LibreFranklin-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

